import { Suspense, useEffect, useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import ReactGA from "react-ga4";
import {
  ItemAssessment,
  ItemConfigurationTypeAssessment,
  ItemQuestion,
  LRSActionType,
  LRSAssessmentStatus,
  LRSProvider,
  LRSProviderState,
  LRSRecord,
  ReferenceKey,
} from "@strmediaochitab/optima-component-library";
import { educationContentTypeState, learningStructureState, questionStateSelector } from "state/learningStructureState";
import { educationStateReferenceKey } from "state/educationState";
import { getLrsState, handleLrsQuestion, handleLrsAssessment, XapiKey, deleteLrsState } from "services/lrsService";
import TestResult, { NavigateTestState, generateOverview } from "layout/test/testResult";
import { useLocation, useNavigate } from "react-router-dom";
import { DialogMenu } from "utils/helpers/DialogMessage";
import { IOverviewQuestion, QuestionOverview } from "utils/helpers/theory/QuestionOverview";
import { AnswerStart } from "utils/helpers/theory/AnswerStart";
import { Assessment } from "utils/helpers/theory/Assessment";
import { TimesUpTestDialog } from "./final/timesUpTestDialog";
import Countdown from "utils/helpers/Countdown";
import { FinishTestDialog } from "./final/finishTestDialog";
import { LeaveTestDialog } from "./final/leaveTestDialog";
import { v4 as uuidv4 } from "uuid";
import { useTopNavigation } from "layout";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import { useAssessment } from "hooks/useAssessment";
import { useTextToSpeech } from "hooks/useTextToSpeech";
import { useAppContext } from "context/AppContext";
import { styleQuestionAnswered } from "theme/styles";
import { loadingState, loadingStateText } from "state/recoilAtoms";
import { useAppIntl } from "services/useAppIntl";
import { appInsights } from "services/microsoftApplicationInsightsService";
import { TestResultSkeleton } from "utils/helpers/LoadingSkeletons";
import { getMapKey } from "utils/helpers/getMapKey";
import useMediaQueries from "hooks/useMediaQueries";

interface ITest {
  assessment: ItemAssessment;
  xapiKey: XapiKey;
  userId: string;
  label: TestLabel;
  isAnswer?: boolean;
}

export type AssessmentInfo = {
  currentQuestionNumber?: number | undefined;
  numberOfQuestions?: number | undefined;
  resultComplete?: boolean | undefined;
  currentQuestion?: ItemQuestion;
};

// Used for display of test type in result list
export enum TestLabel {
  None = "00000000-0000-0000-0000-000000000000",
  Answer = "8d9a110f-d076-4b7b-87d8-8b0a7f705378",
  Final = "f59603d5-a42d-48cd-9cc8-ea225aec9e98",
  StudyQuick = "e8e6a2ec-4b54-4742-b400-433de29cc4e5",
  StudyCustom = "314e4bf6-ef55-4207-971b-df5bd19d97ff",
  RoadsignQuick = "a03397f8-1f0f-4e5c-a1e3-d03ebda1cfa1",
  RoadsignCustom = "6633d30e-195e-40f9-90dc-09b7c09e3725",
}

export const Test = ({ assessment, xapiKey, userId, label, isAnswer }: ITest) => {
  const theme = useTheme();
  const intl = useAppIntl();
  const { soundSupport } = useAppContext();
  const location = useLocation();
  const navigate = useNavigate();
  const navigateState: NavigateTestState = location.state;
  const { setTopNavigate } = useTopNavigation();
  const routes = useRouteConfiguration();
  const { isMobile } = useMediaQueries();
  const setIsLoading = useSetRecoilState(loadingState);
  const setLoadingText = useSetRecoilState(loadingStateText);

  // Needed for result generation
  const educationKey = useRecoilValue(educationStateReferenceKey);
  const { assessment: assessmentStudy } = useAssessment({
    type: "study",
    key: educationKey!,
  });
  const { assessment: assessmentFinal } = useAssessment({
    type: "final",
    key: educationKey!,
  });
  // const { assessment: assessmentRoadsigns } = useAssessment({
  //   type: "roadsigns",
  //   key: educationKey!,
  // });

  const educationContentType = getMapKey(useRecoilValue(educationContentTypeState), xapiKey.stateId);

  const [lrsState, setLrsState] = useState<{ questions: LRSRecord; assessment: LRSProviderState }>();
  const [assessmentInfo, setAssessmentInfo] = useState<AssessmentInfo>();
  const learningStructure = useRecoilValue(learningStructureState);

  const [showResult, setShowResult] = useState(false);
  const [assessmentComplete, setAssessmentComplete] = useState<boolean | undefined>();
  const [hideStartAssessment, setHideStartAssessment] = useState(false);
  const [overview, setOverview] = useState<IOverviewQuestion[]>();
  const [timesUp, setTimesUp] = useState(false);
  const [finish, setFinish] = useState(false);
  const [leave, setLeave] = useState(false);

  const { start, stop, speechInProgress } = useTextToSpeech();

  // Check for location state changes from AnswerStart and ResultOverview
  useEffect(() => {
    if (!navigateState) return;

    if (navigateState.hideResult) setShowResult(false);
    if (navigateState.startAssessment) setHideStartAssessment(true);
    if (navigateState.question) showQuestion(navigateState.question.id, navigateState.question.number);
  }, [navigateState]);

  // Attach cancel function to HeaderNavigation (except for theory answer)
  useEffect(() => {
    ReactGA.event({
      category: "theory",
      action: "test",
    });

    if (assessment.configuration.type !== ItemConfigurationTypeAssessment.AssessmentB)
      setTopNavigate({ function: handleNavigateBack });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!assessment) return;

    const getState = async () => {
      const lrsState = await getLrsState(xapiKey);
      console.log("lrsState fetched", lrsState, "and assessment is", assessment);

      // Find assessment in state
      const assessmentItem = assessment;
      const assessmentKey = assessmentItem.referenceKey.versionId;
      let assessmentState = lrsState.get(assessmentKey);

      if (assessmentState) {
        lrsState.delete(assessmentKey); // Remove it from Map so it only holds the questions
        setAssessmentInfo((currentState) => {
          return {
            ...currentState,
            numberOfQuestions: assessmentState!.questions?.length,
          };
        });
      } else {
        assessmentState = newAssessment();
      }

      setLrsState({ questions: lrsState, assessment: assessmentState });
    };

    const initTest = async () => {
      const lrsState: LRSRecord = new Map<string, LRSProviderState>();
      // Always delete previous test from state
      await deleteLrsState(xapiKey);

      const assessmentState = newAssessment();
      setLrsState({ questions: lrsState, assessment: assessmentState });
    };

    const newAssessment = () => {
      const assessmentState: LRSProviderState = {
        id: uuidv4(),
        user: { id: userId },
        referenceKey: assessment.referenceKey,
      };

      return assessmentState;
    };

    // Only check for existing state when type is "answer" and we´re not doing a reset
    if (isAnswer && !navigateState?.resetTest) getState();
    else initTest();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessment, navigateState?.resetTest, isAnswer]);

  const handleCallbackSetQuestion = async (
    state: LRSProviderState,
    setState?: (state: LRSProviderState) => void,
    action?: LRSActionType,
    assessmentState?: LRSProviderState
  ) => {
    const key = xapiKey;

    if (state.result?.response) setLoadingText(intl.formatMessage({ id: "common.title.loading.question.answer" }));
    setIsLoading(true);
    await handleLrsQuestion({ key, state, setState, action, assessmentState }).finally(() => {
      setIsLoading(false);
      setLoadingText(null);
    });
  };

  const handleCallbackSetAssessment = async (
    state: LRSProviderState,
    setState?: (state: LRSProviderState) => void,
    action?: LRSActionType,
    questionState?: LRSRecord
  ) => {
    const key = xapiKey;
    if (assessmentStudy === null) return console.error("no study assessment found");
    if (assessmentFinal === null) return console.error("no final assessment found");

    setLoadingText(intl.formatMessage({ id: "common.title.loading.result.generate" }));
    setIsLoading(true);
    await handleLrsAssessment({
      key,
      state,
      setState,
      action,
      assessment,
      assessmentTypes: { assessmentStudy, assessmentFinal },
      learningStructure,
      questionState,
      assessmentLabel: label,
    }).finally(() => {
      setIsLoading(false);
      setLoadingText(null);
    });

    // Report creation should be done so navigate to result page
    if (action?.finalized) navigateToResult();

    setAssessmentInfo((currentState) => {
      return {
        ...currentState,
        numberOfQuestions: state.questions?.length,
      };
    });
  };

  const handleCallbackAssessmentStatus = (state: LRSProviderState, status: LRSAssessmentStatus) => {
    switch (true) {
      case status.initialized:
        setLrsState((currentState) => {
          return {
            ...currentState!,
            assessment: state,
          };
        });
        break;
      case status.complete:
        setAssessmentComplete(true);
        break;
    }

    if (state.result?.completion)
      setAssessmentInfo((currentState) => {
        return {
          ...currentState,
          resultComplete: true,
        };
      });
  };

  const handleGetQuestion = async (id: ReferenceKey, number: number) => {
    setAssessmentInfo((currentState) => {
      return {
        ...currentState,
        currentQuestionNumber: number,
      };
    });

    setLoadingText(intl.formatMessage({ id: "common.title.loading.question.get" }));
    setIsLoading(true);
    const question = await getQuestion(id).finally(() => {
      setIsLoading(false);
      setLoadingText(null);
    });

    if (!question) throw new Error("No question found for " + id);

    setAssessmentInfo((currentState) => {
      return {
        ...currentState,
        currentQuestion: question,
      };
    });

    // Error tracking
    appInsights.trackEvent(
      { name: "GetQuestion" },
      { xapiKey, question: question.id, assessment: assessment.referenceKey, lrsState: lrsState }
    );

    return question;
  };

  const handleGetOverview = async (overviewQuestions: LRSRecord | null) => {
    if (!overviewQuestions) return;

    const questions = generateOverview(overviewQuestions);
    setOverview(questions);
  };

  const getQuestion = useRecoilCallback(
    ({ snapshot }) =>
      async (id: ReferenceKey) => {
        const question = await snapshot.getPromise(questionStateSelector({ key: id }));
        return question;
      },
    []
  );

  const showQuestion = (id: ReferenceKey, number: number) => {
    setLrsState((currentState) => {
      return {
        ...currentState!,
        assessment: {
          ...currentState!.assessment,
          currentQuestion: { id, number },
        },
      };
    });
    setOverview(undefined);
  };

  const handleLeaveTest = () => {
    setLeave(false);
    navigate(routes.test.path, { replace: true });
  };

  const handleNavigateBack = () => {
    if (assessmentInfo?.resultComplete) handleLeaveTest();
    else setLeave(true);
  };

  const navigateToResult = () => {
    if (!lrsState) throw new Error("No lrsState found");

    if (isAnswer) return setShowResult(true);

    if (label === TestLabel.Final) {
      setLoadingText(intl.formatMessage({ id: "common.title.loading.result.generate" }));
      setIsLoading(true);
      return navigate(routes.test.path + "/" + routes.test.routes!.finaltestresult.path, {
        replace: true,
        state: { from: location, testId: lrsState.assessment.id },
      });
    }

    navigate(routes.test.path + "/" + routes.test.routes!.result.path.replace(":id", lrsState.assessment.id), {
      replace: true,
    });
  };

  // Temp for DEV only
  const deleteTest = () => {
    deleteLrsState(xapiKey);
    navigate("/");
  };

  const styleWrapper = {
    marginLeft: isMobile ? 0 : "16px",
    marginRight: isMobile ? 0 : "16px",
    color: theme.palette.text.primary,
  };

  // Make sure we wait for LRS before initializing the LRSProvider
  if (!lrsState) return null;

  // New assessment (answer)
  if (isAnswer) {
    if (lrsState.assessment && lrsState.assessment.questions && !lrsState.questions.size && !hideStartAssessment)
      return (
        <Box
          mt={theme.spacing(isMobile ? 0 : 4)}
          sx={[
            styleWrapper,
            {
              padding: "16px",
              height: isMobile ? "auto" : "86vh",
            },
          ]}
        >
          <AnswerStart numberOfQuestions={lrsState.assessment.questions!.length} />
        </Box>
      );
  }

  return (
    <Box
      sx={[
        styleWrapper,
        styleQuestionAnswered,
        {
          padding: isMobile ? "40px 0" : "24px 16px",
        },
      ]}
    >
      {process.env.NODE_ENV === "development" && (
        <Button
          onClick={deleteTest}
          variant="contained"
          color="error"
          size="small"
          sx={{ position: "absolute", zIndex: 5000, top: "10px", right: "10px" }}
        >
          Radera test (bara i dev)
        </Button>
      )}

      <LRSProvider
        user={userId}
        textToSpeech={
          soundSupport
            ? {
                start: start,
                stop: stop,
                speechInProgress: speechInProgress,
              }
            : undefined
        }
        callbackSetQuestion={handleCallbackSetQuestion}
        callbackSetAssessment={handleCallbackSetAssessment}
        callbackGetQuestion={handleGetQuestion}
        callbackAssessmentStatus={handleCallbackAssessmentStatus}
        callbackGetOverview={handleGetOverview}
        state={lrsState}
        themeOptions={{ typography: theme.custom.typographyLearning, palette: theme.palette, shape: theme.shape }}
      >
        {showResult ? (
          <Suspense fallback={<TestResultSkeleton />}>
            <TestResult xapiKey={xapiKey} assessmentId={lrsState.assessment.id} />
          </Suspense>
        ) : (
          <>
            {!assessmentInfo?.resultComplete && assessment.configuration.duration && (
              <Box mt={-2}>
                <Countdown minutes={assessment.configuration.duration} onFinish={() => setTimesUp(true)} />
                <TimesUpTestDialog open={timesUp} close={() => setTimesUp(false)} />
              </Box>
            )}
            <Assessment
              assessment={assessment}
              assessmentInfo={assessmentInfo}
              complete={assessmentComplete}
              showResult={navigateToResult}
              showFinishDialog={() => setFinish(true)}
              type={educationContentType}
            >
              {overview !== undefined && (
                <DialogMenu
                  open
                  close={() => setOverview(undefined)}
                  title={intl.formatMessage({ id: "common.question-overview" })}
                  divider
                >
                  <QuestionOverview questions={overview}></QuestionOverview>
                </DialogMenu>
              )}
            </Assessment>
          </>
        )}
        <FinishTestDialog open={finish} close={() => setFinish(false)} />
        <LeaveTestDialog open={leave} leave={handleLeaveTest} cancel={() => setLeave(false)} />
      </LRSProvider>
    </Box>
  );
};
