export const appConfig = {
  appId: window?.env?.APP_ID,
  appTheme: window?.env?.APP_THEME,
  finalTestSettings: window.env.APP_FINAL_TEST_SETTING,

  authClientId: window?.env?.AUTH_CLIENT_ID,
  authTenant: window?.env?.AUTH_TENANT,
  authTenantName: window?.env?.AUTH_TENANT_NAME,

  authCustomPolicySignIn: window?.env?.AUTH_CUSTOM_POLICY_SIGN_IN,
  authUserFlowPasswordReset: window?.env?.AUTH_USER_FLOW_PASSWORD_RESET,
  authUseVerboseLogging: window?.env?.AUTH_USE_VERBOSE_LOGGING,

  // # Api scopes
  authScopesLrsRead: window?.env?.AUTH_SCOPES_LRS_READ,
  authScopesLrsWrite: window?.env?.AUTH_SCOPES_LRS_WRITE,
  authScopesCdsRead: window?.env?.AUTH_SCOPES_CDS_READ,
  authScopesAssRead: window?.env?.AUTH_SCOPES_ASS_READ,
  authScopesAssWrite: window?.env?.AUTH_SCOPES_ASS_WRITE,
  authScopesFfsRead: window?.env?.AUTH_SCOPES_FFS_READ,
  authScopesLesRead: window?.env?.AUTH_SCOPES_LES_READ,
  authScopesLesWrite: window?.env?.AUTH_SCOPES_LES_WRITE,
  authScopesCsRead: window?.env?.AUTH_SCOPES_CS_READ,
  authScopesCsWrite: window?.env?.AUTH_SCOPES_CS_WRITE,

  // # Api settings
  cdsApiUrl: window?.env?.CDS_API_URL,
  lrsApiUrl: window?.env?.LRS_API_URL,
  assApiUrl: window?.env?.ASS_API_URL,
  ffsApiUrl: window?.env?.FFS_API_URL,
  lesApiUrl: window?.env?.LES_API_URL,
  csApiUrl: window?.env?.CS_API_URL,

  debug_recoil: window?.env?.DEBUG_RECOIL ?? "false",
  app_insights_connection_string: window?.env?.APP_INSIGHTS_CONNECTION_STRING ?? "",
};

/**
 * Indicates whether there are any errors in env-config.
 */
let hasErrors = false;
for (const [key, value] of Object.entries(appConfig)) {
  if (value === null || value === "" || value === undefined) {
    hasErrors = true;
    console.error(`Missing value for env variable ${key} in env-config`);
  }
}
if (hasErrors) throw new Error("env-config has missing entries");
