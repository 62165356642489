import { WelcomeMessage } from "utils/helpers/WelcomeMessage";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import EducationList from "./EducationHandler";

export const Education = () => {
  return (
    <>
      <WelcomeMessage />
      <FormattedTypography id="common.title.my-educations" variant="h2" mb={5} />
      <EducationList />
    </>
  );
};
