import {
  Avatar,
  Box,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Theme,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { ReactNode } from "react";
import { useAppContext } from "./AppContext";

// Customer logos
import logoFM from "static/logo-FM.png";
import logoFlex from "static/logo-Flex.png";

// Customer themes. Import the themes that should be used from the theme folder
import { theme as themeFM } from "theme/FM/theme";
import { theme as themeFlex } from "theme/Flex/theme";
import { Icon, iconTheme } from "theme/icons";
import { appConfig } from "appConfig";

// Define the theme name for the customer
export type ThemeName = "FM" | "Flex";
type ThemeItem = { icon: ReactNode; name: ThemeName };

const themes: ThemeItem[] = [
  { icon: <Avatar src={logoFM} sx={{ width: 24, height: 24 }} />, name: "FM" },
  {
    icon: (
      <Avatar
        src={logoFlex}
        sx={{ width: 24, height: 24, backgroundColor: "primary", "> img": { objectFit: "scale-down" } }}
      />
    ),
    name: "Flex",
  },
];

const ThemeContextProvider = ({ children }: { children: ReactNode }) => {
  const { themeName } = useAppContext();

  // Default theme
  let themeOptions = themeFlex;

  // Load theme based on theme name
  if (themeName === "FM") {
    themeOptions = themeFM;
  }

  console.log("Creating theme:", themeName);
  const theme: Theme = createTheme(themeOptions("ltr"));

  return (
    <ThemeProvider theme={theme}>
      {children}

      {process.env.NODE_ENV === "development" && <ThemeMenu />}
    </ThemeProvider>
  );
};

export default ThemeContextProvider;

const ThemeMenu = () => {
  const { setThemeName } = useAppContext();

  const handleSetTheme = (themeName: ThemeName) => {
    appConfig.appTheme = themeName;
    setThemeName(themeName);
  };

  return (
    <Box sx={{ transform: "translateZ(0px)", flexGrow: 1, position: "fixed", bottom: 90, right: 20 }}>
      <SpeedDial ariaLabel="Theme menu" icon={<SpeedDialIcon icon={<Icon name={iconTheme} />} />} color="info">
        {themes.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={`Optima ${action.name}`}
            onClick={() => handleSetTheme(action.name)}
          />
        ))}
      </SpeedDial>
    </Box>
  );
};
