import { TreeNode } from "types/cds";
import { atom, selector, selectorFamily } from "recoil";
import { storageEffect } from "./stateHelper";
import { iconCity, iconSeedling, iconRoad } from "theme/icons";
import { OverviewQuestionStatus } from "utils/helpers/theory/QuestionOverview";

export const theoryState = atom<TreeNode[]>({
  key: "theory",
  default: [],
  effects: [storageEffect("local", "theory")],
});

// TODO: Reda ut om denna behövs. Vi sätter samma nod på theoryAreaState
export const theoryStateSelected = atom<TreeNode>({
  key: "theorySelected",
});

export const theoryStudyHelpFilter = atom<OverviewQuestionStatus | undefined>({
  key: "theoryStudyHelpFilter",
  default: undefined,
});

export const theoryStateChildren = selector<TreeNode[]>({
  key: "theoryChildren",
  get: ({ get }) => {
    const list = get(theoryState);
    const selected = get(theoryStateSelected);
    let children: TreeNode[] = [];

    if (selected) children = list.filter((activity) => activity.parentId === selected.id);

    return children;
  },
});

export const theoryStateAreas = selector<TreeNode[]>({
  key: "theoryStateAreas",
  get: ({ get }) => {
    const areas = get(theoryStateChildrenByParentId(1));

    const areasWithIcon = areas.map((item: TreeNode) => {
      const itemWithIcon = { ...item };
      switch (item.title) {
        case "Stad":
          itemWithIcon.icon = iconCity;
          break;
        case "Landsväg":
          itemWithIcon.icon = iconRoad;
          break;
        default:
          itemWithIcon.icon = iconSeedling;
          break;
      }

      return itemWithIcon;
    });

    return areasWithIcon;
  },
});

export const theoryStateChildrenByParentId = selectorFamily<TreeNode[], number>({
  key: "theoryChildrenByParentId",
  get:
    (id: number) =>
    ({ get }) => {
      const list = get(theoryState);
      const children = list.filter((activity) => activity.parentId === id);

      return children;
    },
});

export const theoryStateById = selectorFamily<TreeNode | null, number>({
  key: "theoryByParentId",
  get:
    (id: number) =>
    ({ get }) => {
      const list = get(theoryState);
      const items = list.filter((activity) => activity.id === id);

      if (items.length) return items[0];

      return null;
    },
});

export const theoryAreaState = atom<TreeNode | null>({
  key: "theoryArea",
  default: null,
  effects: [storageEffect("local", "theory-area")],
});

export const theoryChapterState = atom<TreeNode | null>({
  key: "theoryChapter",
  default: null,
  effects: [storageEffect("local", "theory-chapter")],
});

export const theorySectionState = atom<TreeNode | null>({
  key: "theorySection",
  default: null,
});

export const theorySectionScrollSpyState = atom<string | null>({
  key: "theorySectionScrollSpy",
  default: null,
});

export const theoryStateChapterNavigationStatus = selector<{ isFirst: boolean; isLast: boolean }>({
  key: "theoryStateChapterNavigationStatus",
  get: ({ get }) => {
    const item = get(theoryChapterState);
    if (!item) return { isFirst: true, isLast: true };

    const chapters = get(theoryStateChildrenByParentId(item.parentId));
    let index = chapters.findIndex((chapter) => chapter.id === item.id);

    return { isFirst: index === 0, isLast: index === chapters.length - 1 };
  },
});
