import { Typography, SvgIconTypeMap } from "@mui/material";
import { SwipeableListItem, Type } from "react-swipeable-list";
import { BookmarkState } from "services/appStateService";
import { testId } from "tests/testIdStrings";
import { ListItemButton } from "utils/helpers/ListItemButton";
import { Icon, iconBookmarkSolid, iconArrowRightRegular } from "theme/icons";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import { swipeLeftAction } from "./bookmarksListItemSwipeAction";
import "react-swipeable-list/dist/styles.css";

type Props = {
  key: number;
  item: BookmarkState;
  handleDeleteDialog: (item: BookmarkState) => void;
};

export const SwipeableBookmarksListItem = ({ key, item, handleDeleteDialog }: Props) => {
  const routes = useRouteConfiguration();
  const index = key;
  return (
    <SwipeableListItem listType={Type.IOS} trailingActions={swipeLeftAction(item, handleDeleteDialog)}>
      <ListItemButton
        key={item.key.contentId}
        primaryText={
          <Typography variant="breadcrumbs" noWrap display="block">
            {item.title}
          </Typography>
        }
        secondaryText={
          <Typography variant="note" noWrap maxWidth="92%" display="inline-block">
            {item.text}
          </Typography>
        }
        to={routes.bookmarksDetail.path.replace(":id", item.key.contentId) + "?type=" + item.type}
        dataTestIdString={`${testId.button.bookmarkItem}.${index + 1}`}
        startIcon={<Icon name={iconBookmarkSolid} color={item.color as SvgIconTypeMap["props"]["color"]} />}
        endText={
          <Typography variant="caption" sx={{ whiteSpace: "nowrap" }}>
            {item.date}
          </Typography>
        }
        endIcon={<Icon name={iconArrowRightRegular} color="info" />}
      />
    </SwipeableListItem>
  );
};
