import { Route, Routes } from "react-router-dom";
import { NotFound } from "layout/notFound";
import { TheoryLayout, TestLayout, DefaultLayout, TheoryChaptersLayout, MoreLayout } from "layout";
import { Unauthorized } from "layout/unauthorized";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import { Box } from "@mui/material";
import { ResetTest } from "layout/test/testResult";
import { ProtectedRoutes } from "./protectedRoutes";
import { Start } from "layout/start";

const StudentRoutes = () => {
  const routes = useRouteConfiguration();
  return (
    <Routes>
      <Route path="/" element={<ProtectedRoutes />}>
        <Route element={<DefaultLayout />}>
          <Route index element={routes.home.component} />
          <Route path={routes.theory.path} element={routes.theory.component} />
          <Route path={routes.test.path} element={routes.test.component} />
          <Route path={routes.more.path} element={routes.more.component} />
          <Route path={routes.progress.path} element={routes.progress.component} />

          <Route path="*" element={<NotFound />} />
        </Route>

        <Route element={<DefaultLayout hideEducations hideFooter hideNavigation />}>
          <Route path={routes.education.path}>
            <Route index element={routes.education.component} />
          </Route>
        </Route>

        {/* TODO: Byt namn på denna layot till nåt mer generellt */}
        <Route element={<TheoryChaptersLayout />}>
          <Route path={routes.theoryChapters.path} element={routes.theoryChapters.component} />
        </Route>

        <Route element={<TheoryLayout />}>
          <Route path={routes.theoryRead.path} element={routes.theoryRead.component} />
          <Route path={routes.theoryPractice.path} element={routes.theoryPractice.component} />
          <Route path={routes.theoryAnswer.path} element={routes.theoryAnswer.component} />
        </Route>

        <Route element={<TestLayout />}>
          {Object.entries(routes.test.routes!).map(([key, route]) => (
            <Route key={key} path={`${routes.test.path}/${route.path}`} element={route.component}>
              {Object.entries(route.routes ?? []).map(([key, subRoute]) => (
                <Route key={key} path={`${subRoute.path}`} element={subRoute.component} />
              ))}
            </Route>
          ))}
          {Object.entries(routes.progress.routes!).map(([key, route]) => (
            <Route key={key} path={`${routes.progress.path}/${route.path}`} element={route.component}>
              {Object.entries(route.routes ?? []).map(([key, subRoute]) => (
                <Route key={key} path={`${subRoute.path}`} element={subRoute.component} />
              ))}
            </Route>
          ))}
          {Object.entries(routes.theory.routes!).map(([key, route]) => (
            <Route key={key} path={`${routes.theory.path}/${route.path}`} element={route.component}>
              {Object.entries(route.routes ?? []).map(([key, subRoute]) => (
                <Route key={key} path={`${subRoute.path}`} element={subRoute.component} />
              ))}
            </Route>
          ))}
        </Route>

        <Route element={<MoreLayout />}>
          <Route path={routes.profile.path} element={routes.profile.component} />

          <Route path={routes.agreement.path} element={routes.agreement.component} />
          <Route path={routes.checklist.path} element={routes.checklist.component} />
          <Route path={routes.bookmarks.path} element={routes.bookmarks.component} />
          <Route path={routes.bookmarksDetail.path} element={routes.bookmarksDetail.component} />
          <Route path={routes.support.path} element={routes.support.component} />
          <Route path={routes.language.path} element={routes.language.component} />
          <Route path={routes.settings.path} element={routes.settings.component} />
          <Route path={routes.glossary.path} element={routes.glossary.component} />
          <Route path={routes.glossaryDetail.path} element={routes.glossaryDetail.component} />
          <Route path={routes.roadsigns.path} element={routes.roadsigns.component} />
          <Route path={routes.myeducation.path} element={routes.myeducation.component} />
          <Route path={routes.getstarted.path} element={routes.getstarted.component} />
        </Route>
      </Route>

      <Route path="/start" element={<Start />} />
      <Route path="/resetTest" element={<ResetTest />} />
      <Route path="/auth-redirect" element={<Box />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
    </Routes>
  );
};

export default StudentRoutes;
