import { appConfig } from "appConfig";
import { TypographyProps, Typography } from "@mui/material";

// Logos
import logoFM from "static/logo-FM.png";
import logoFlex from "static/logo-Flex.png";

export const AppTitle = ({ variant }: { variant?: TypographyProps["variant"] }) => {
  let title = "Optima " + appConfig.appTheme;

  // Varför i h.....e är detta omvänt?
  if (appConfig.appTheme === "FM") title = appConfig.appTheme + " Optima";

  return <Typography variant={variant ?? "h4"}>{title}</Typography>;
};

export const getAppLogo = () => {
  const logos = {
    FM: logoFM,
    Flex: logoFlex,
  };

  return logos[appConfig.appTheme as keyof typeof logos];
};
