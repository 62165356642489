import { Button, ListItemButton, Typography } from "@mui/material";
import { useDateService } from "hooks/useDateService";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { educationState } from "state/educationState";
import { UserEducation } from "types/education";
import { LearningEventMaterial } from "types/learningEvent";
// import { LinearProgressWithLabel } from "utils/helpers/LinearProgressIndicator";

type EducationItemProps = {
  item: LearningEventMaterial;
};
const EducationItem = ({ item }: EducationItemProps) => {
  const setEducation = useSetRecoilState(educationState);
  const navigate = useNavigate();
  const { getLocalDate } = useDateService();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (event.target instanceof HTMLButtonElement) return;

    // TODO: fix this type issue
    setEducation(item as unknown as UserEducation);
    navigate(`education/${item.id}`);
  };

  return (
    <ListItemButton
      component="a"
      sx={{
        "& > *": { flexGrow: 1, flexBasis: 0 },
        backgroundColor: "#F7F7F7",
        px: 2,
        borderRadius: 1,
        border: "1px solid #F2F0EE",
        minHeight: 48,
      }}
      onClick={handleClick}
    >
      <Typography variant="captionImage" sx={{ fontWeight: 600 }}>
        {item.name}
      </Typography>
      <Typography variant="breadcrumbs">
        {getLocalDate(item.start!)} - {item.end ? getLocalDate(item.end) : "Tills vidare"}
      </Typography>
      {/* <LinearProgressWithLabel value={32} color="success" /> */}
      {/* <CompleteEducation /> */}
    </ListItemButton>
  );
};

export default EducationItem;

const CompleteEducation = () => {
  // const { fetchData } = useFetch();

  const handleDelete = async () => {
    // await fetchData(`${API_URL_LES}/education`, "DELETE");

    alert("Vill du verkligen avsluta utbildningen? (TODO)");
  };

  return (
    <Button variant="text" color="info" sx={{ flexGrow: 0, minWidth: "auto", height: "auto" }} onClick={handleDelete}>
      Avsluta
    </Button>
  );
};
