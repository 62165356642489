import { ReactNode } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import {
  useLRSProviderContext,
  Assessment as AssessmentComponent,
  ItemAssessment,
  ItemConfigurationTypeAssessment,
  ItemQuestion,
  ItemText,
} from "@strmediaochitab/optima-component-library";
import { useFeatureFlag } from "@strmediaochitab/optima-react-components";
import { testId } from "tests/testIdStrings";
import {
  LearningObjecActions,
  LearningObjectBookmark,
  LearningObjectListen,
} from "utils/helpers/theory/LearningObjectActions";
import { FormattedMessage } from "react-intl";
import { AssessmentInfo } from "layout/test/test";
import { EducationContentTypes } from "state/learningStructureState";

function getQuestionTitle(testList: ItemQuestion["items"]) {
  let title = "";
  for (let i = 0; i < testList.length; i++) {
    if (testList[i].type === "Base") {
      for (let k = 0; k < testList[i].items.length; k++) {
        if (testList[i].items[k].type === "Stem") {
          const stem = testList[i].items[k];
          const text = stem.items.find((item) => item.type === "Text") as ItemText | undefined;
          title = text ? text.value : "";
        }
      }
    }
  }

  return title;
}

interface AssessmentProps {
  assessment: ItemAssessment;
  assessmentInfo?: AssessmentInfo;
  children: ReactNode;
  complete?: boolean;
  type?: EducationContentTypes;
  showResult: () => void;
  showFinishDialog: () => void;
}
export const Assessment = ({
  assessment,
  assessmentInfo,
  children,
  complete,
  type,
  showResult,
  showFinishDialog,
}: AssessmentProps) => {
  const { trackAssessment } = useLRSProviderContext();
  const theme = useTheme();
  const useBookmarks = useFeatureFlag("Bookmarks");
  const canBookmark = type !== "final";
  let bookMarkActivity = undefined;
  let questionId = "";

  if (assessmentInfo && assessmentInfo.currentQuestion) {
    const currentQuestion = assessmentInfo.currentQuestion as ItemQuestion;
    bookMarkActivity = { key: currentQuestion.id, title: getQuestionTitle(currentQuestion.items) };
    questionId = currentQuestion.configuration.friendlyId;
  }

  const handleShowResult = () => {
    if (assessmentInfo?.resultComplete) showResult();
    else trackAssessment();
  };

  const handleFinish = () => {
    if (assessmentInfo?.resultComplete) showResult();
    else showFinishDialog();
  };

  return (
    <>
      <LearningObjecActions sx={{ mb: 3 }}>
        <LearningObjectListen dataTestIdString={`${testId.listItem.section.listen}`} />
        <Typography variant="body2" component={"div"} data-testid={testId.listItem.test.common.pagination}>
          {assessmentInfo?.currentQuestionNumber}/{assessmentInfo?.numberOfQuestions}
        </Typography>
        {/* <LearningObjectStar dataTestIdString={`${testId.listItem.section.star}`} /> */}
        {useBookmarks && canBookmark && bookMarkActivity && (
          <LearningObjectBookmark activity={bookMarkActivity} activityType={type} />
        )}
      </LearningObjecActions>

      <AssessmentComponent data={assessment} />

      {/* answer "test" = AssessmentB */}
      {assessment.configuration.type === ItemConfigurationTypeAssessment.AssessmentB ? (
        <Button
          variant="contained"
          fullWidth
          sx={{ px: 2, mt: 3 }}
          data-testid={testId.listItem.test.common.result}
          onClick={handleShowResult}
          disabled={!complete}
        >
          <FormattedMessage id="question.show-result" />
        </Button>
      ) : (
        <Button
          variant="contained"
          fullWidth
          sx={{ px: 2, mt: 3 }}
          data-testid={testId.listItem.test.common.close}
          onClick={handleFinish}
        >
          <FormattedMessage id={assessmentInfo?.resultComplete ? "common.close" : "common.exit"} />
        </Button>
      )}

      {/* Mainly used for overview dialog */}
      {children}
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <Typography variant="caption" component={"div"} color={theme.palette.grey[500]}>
          Fråge-Id: {questionId}
        </Typography>
      </Box>
    </>
  );
};
